export const state = () => ({
  isDemo: false,
  isDevDemo: false,
  trackSurvey: true
});

export const mutations = {
  setState(state, payload) {
    state[payload.key] = payload.data;
  }
};

export const getters = {
  isDemo: state => {
    return state.isDemo;
  },

  isDevDemo: state => {
    return state.isDevDemo;
  }
};
